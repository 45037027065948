var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-3 mb-3",attrs:{"cols":"12","lg":"5"}},[_c('span',{staticClass:"custom-medium-text text-green"},[_vm._v(" Escolha o melhor plano para você. ")]),(!_vm.loading && !!_vm.showSimpliesNacional && !!_vm.showMei)?_c('v-col',{staticClass:"pa-0 mt-2",attrs:{"cols":"12"}},[_c('v-item-group',{on:{"change":_vm.setLoading},model:{value:(_vm.juridicType),callback:function ($$v) {_vm.juridicType=$$v},expression:"juridicType"}},[(!!_vm.showSimpliesNacional)?_c('v-item',{attrs:{"value":"me"},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{class:[
                'rounded-0 mb-1',
                active ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
              ],attrs:{"block":"","small":""},on:{"click":toggle}},[_vm._v(" Planos para Simples Nacional ")])]}}],null,false,2979792117)}):_vm._e(),(!!_vm.showMei)?_c('v-item',{attrs:{"value":"mei"},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{class:[
                'rounded-0',
                active ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
              ],attrs:{"block":"","small":""},on:{"click":toggle}},[_vm._v(" Planos para MEI ")])]}}],null,false,3390544723)}):_vm._e()],1)],1):_vm._e()],1),_c('v-col',[(!_vm.loading)?_c('plansCard',{attrs:{"loading":_vm.loadingBnt,"plans":_vm.plansByJuridicType,"disabled":_vm.btnDisabledPlan},on:{"selectedAPlan":function($event){return _vm.goToPayment($event)}}}):_vm._e()],1)],1),(_vm.loading)?_c('planComparativeLoadin',{attrs:{"listOfCoparativeType":_vm.listOfCoparativeType}}):_vm._e(),(!_vm.loading)?_c('comparativeTable',{attrs:{"plans":_vm.plansByJuridicType,"listOfCoparativeType":_vm.listOfCoparativeType,"loading":_vm.loading,"juridicType":_vm.juridicType,"activities":_vm.ActivitiesByPlanClassificationType},on:{"selectedAPlan":function($event){return _vm.goToPayment($event)}}}):_vm._e(),_c('plansSmallBtn',{attrs:{"loading":_vm.loadingBnt,"plans":_vm.plansByJuridicType,"disabled":_vm.btnDisabledPlan},on:{"selectedAPlan":function($event){return _vm.goToPayment($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }