<template>
  <plans :loading.sync="loading" />
</template>

<script>
import plans from './plans.vue'

export default {
  data() {
    return {
      loading: true,
    }
  },
  components: {
    plans,
  },
  computed: {},
  async mounted() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('plans/getPlans'),
      this.$store.dispatch('paymentInfo/getPaymentInfoByRegistering'),
      this.$store.dispatch('company/getCurrentCompany'),
    ])
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
.s-quiz-title {
  font-size: 1.5rem;
}
.s-quiz-subheading {
  font-size: 1.125rem;
}
.s-min-height {
  min-width: 980px;
  @media only screen and (max-width: 1263px) {
    min-width: 900px;
  }
  @media only screen and (max-width: 960px) {
    min-width: auto;
  }
}
</style>
