<template>
  <div>
    <v-row no-gutters class="mb-6">
      <v-col cols="12" lg="5" class="mr-3 mb-3">
        <span class="custom-medium-text text-green">
          Escolha o melhor plano para você.
        </span>
        <v-col
          class="pa-0 mt-2"
          cols="12"
          v-if="!loading && !!showSimpliesNacional && !!showMei"
        >
          <v-item-group @change="setLoading" v-model="juridicType">
            <v-item
              v-slot="{ active, toggle }"
              value="me"
              v-if="!!showSimpliesNacional"
            >
              <v-btn
                @click="toggle"
                :class="[
                  'rounded-0 mb-1',
                  active ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
                ]"
                block
                small
              >
                Planos para Simples Nacional
              </v-btn>
            </v-item>
            <v-item v-slot="{ active, toggle }" value="mei" v-if="!!showMei">
              <v-btn
                @click="toggle"
                block
                :class="[
                  'rounded-0',
                  active ? 'custom-btn-blue' : 'custom-btn-blue-inverse',
                ]"
                small
              >
                Planos para MEI
              </v-btn>
            </v-item>
          </v-item-group>
        </v-col>
      </v-col>
      <v-col>
        <plansCard
          v-if="!loading"
          :loading="loadingBnt"
          :plans="plansByJuridicType"
          @selectedAPlan="goToPayment($event)"
          :disabled="btnDisabledPlan"
        />
      </v-col>
    </v-row>

    <planComparativeLoadin
      v-if="loading"
      :listOfCoparativeType="listOfCoparativeType"
    />

    <comparativeTable
      :plans="plansByJuridicType"
      :listOfCoparativeType="listOfCoparativeType"
      v-if="!loading"
      :loading="loading"
      :juridicType="juridicType"
      :activities="ActivitiesByPlanClassificationType"
      @selectedAPlan="goToPayment($event)"
    />
    <plansSmallBtn
      :loading="loadingBnt"
      :plans="plansByJuridicType"
      @selectedAPlan="goToPayment($event)"
      :disabled="btnDisabledPlan"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { paymentMethods } from '@state/helpers'
import comparativeTable from '@/components/compatativePlan/comparativeTable.vue'
import plansCard from '@/components/compatativePlan/plansCard.vue'
import plansSmallBtn from '@/components/compatativePlan/plansSmallBtn.vue'
import planComparativeLoadin from '@/components/compatativePlan/planComparativeLoadin.vue'
export default {
  props: ['loading'],
  components: {
    plansCard,
    comparativeTable,
    planComparativeLoadin,
    plansSmallBtn,
  },
  data() {
    return {
      juridicType: 'me',
      loadingBnt: false,
      btnDisabledPlan: false,
      selectedPlan: null,
    }
  },
  computed: {
    ...mapGetters({
      plans: 'plans/plans',
      company: 'company/company',
    }),
    showMei() {
      if (this.company.isOppening) {
        return (
          (this.company?.hasPartner == false &&
            [1, 4, 5].includes(this.company?.idSalesForecast)) ||
          [4, 5].includes(this.company?.inputTypeId)
        )
      }
      return (
        this.company?.juridicType == 'mei' ||
        [4, 5].includes(this.company?.inputTypeId)
      )
    },
    showSimpliesNacional() {
      return [1, 2, 3].includes(this.company.inputTypeId)
    },
    plansByJuridicType() {
      return this.plans.filter(
        (plan) => plan.type.toLowerCase() === this.juridicType.toLowerCase()
      )
    },
    ActivitiesByPlanClassificationType() {
      let temp = {}
      this.plansByJuridicType.map((plan) => {
        temp[plan.classification] = plan.additionals
      })
      return temp
    },
    listOfCoparativeType() {
      if (this.juridicType?.toLowerCase() == 'mei') {
        return ['complete']
      } else {
        return ['basic', 'complete']
      }
    },
  },
  methods: {
    ...paymentMethods,
    async goToPayment(id) {
      this.selectedPlan = this.plans.find((plan) => plan.id === id)
      this.loadingBnt = true
      this.btnDisabledPlan = true
      await this.selectPlan({
        plan_id: id,
        current_price_id: this.selectedPlan.currentPrice.id,
      })
      await this.saveCompanyPaymentInfoField({ step: 1 })
      await this.setCurrentInputType()
      await this.$store.dispatch('auth/updateUser', { idStepRegistration: 3 })
      this.loadingBnt = false
      this.btnDisabledPlan = false
      this.$router.push({ name: 'payment' })
    },
    async setCurrentInputType() {
      if (this.selectedPlan.type.toLowerCase() !== 'mei') return
      let inputTypeId = null
      switch (this.company.inputTypeId) {
        case 1:
          inputTypeId = 4
          break
        case 2:
          inputTypeId = 5
          break
        case 3:
          inputTypeId = 3
          break
      }
      let data = { juridicType: 'mei', inputTypeId: inputTypeId }
      this.$emit('update:loading', true)
      await this.$store.dispatch('company/updateCompany', data)
      this.$emit('update:loading', false)
    },
    setLoading() {
      this.$emit('update:loading', true)
      setTimeout(() => {
        this.$emit('update:loading', false)
      }, 500)
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
